import React from "react"
import PropTypes from "prop-types"
import FormSection from "./FormSection"
import Cushion from "../ui/Cushion"
import Grid from "../ui/Grid"
import MaxWidth from "../ui/MaxWidth"
import Typography from "../ui/Typography"
import SelectBox from "../SelectBox"
import TankSystemIcon from "./icons/TankSystem"
import CombiBoilerIcon from "./icons/CombiBoiler"

const boilerTypes = [
  {
    id: "combi",
    renderIcon: color => <CombiBoilerIcon fill={color} />,
    value: "Combi Boiler",
  },
  {
    id: "tank",
    renderIcon: color => <TankSystemIcon fill={color} />,
    value: "Tank & Cylinder System",
  },
]

function validate(values) {
  const errors = []
  console.log("validate(values) values", values)

  if (!values.boilerType) {
    errors.push("Please select which type of boiler you have")
  }

  return errors
}

const Boiler = ({ form, setForm, isEnd, onBack, onNext, visible }) => {
  const boilerType = form.values.boilerType

  console.log("form", form)

  return (
    <FormSection
      question="What type of boiler do you have?"
      description="Select the type of boiler that applies to your home"
      isEnd={isEnd}
      onBack={onBack}
      onNext={onNext}
      validate={validate}
      visible={visible}
      values={form.values}
      renderAnswers={() => (
        <Grid
          component="div"
          columns="1fr"
          responsive={[
            {
              minWidth: 900,
              props: {
                columns: "repeat(2, 1fr)",
                columnGap: "medium",
                rowGap: "medium",
              },
            },
          ]}
        >
          {boilerTypes.map(({ id, renderIcon, value }) => {
            const checked = boilerType === value
            return (
              <p>
                <SelectBox
                  id={id}
                  type="radio"
                  checked={checked}
                  onChange={() => {
                    setForm({
                      ...form,
                      values: { ...form.values, boilerType: value },
                    })
                  }}
                  input={{ value, name: "boilerType" }}
                >
                  <MaxWidth
                    component="div"
                    center
                    maxWidth={100}
                    style={{ width: "100%" }}
                  >
                    {renderIcon(checked ? "#FF7373" : "#D8D8D8")}
                  </MaxWidth>
                  <Cushion top="medium">
                    <Typography component="div" align="center">
                      {value}
                    </Typography>
                  </Cushion>
                </SelectBox>
              </p>
            )
          })}
        </Grid>
      )}
    />
  )
}

export default Boiler

// import React from "react"
// import PropTypes from "prop-types"
// import FormSection from "./FormSection"

// const Boiler = ({ visible, isEnd, onBack, onNext }) => {
//   return (
//     <FormSection
//       question="What type of boiler do you have?"
//       description="Select the type of boiler that applies to your home"
//       renderAnswers={() => <input type="text" name="boiler" id="boiler" value="baxi"  />}
//       isEnd={isEnd}
//       onBack={onBack}
//       onNext={onNext}
//       visible={visible}
//     />
//   )
// }

// export default Boiler
