import React from "react"

const NewBoiler = ({ fill }) => {
  return (
    <svg width="100%" length="auto" viewBox="0 0 360 360" version="1.1">
      <title>New Boiler</title>
      <g
        id="New-Boiler"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Combi-Boiler"
          transform="translate(84.000000, 7.000000)"
          fillRule="nonzero"
        >
          <polygon
            id="Rectangle"
            fill={fill}
            points="42 297 59 297 59 346 42 346"
          ></polygon>
          <path
            d="M133,297 L150,297 L150,346 L133,346 L133,297 Z M98,312 L98,313"
            id="Rectangle-Copy-2"
            fill={fill}
          ></path>
          <path
            d="M20,0 L172,0 C183.045695,0 192,8.86476195 192,19.8 L192,277.2 C192,288.135238 183.045695,297 172,297 L20,297 C8.954305,297 0,288.135238 0,277.2 L0,19.8 C0,8.86476195 8.954305,0 20,0 Z"
            id="Rectangle"
            fill={fill}
          ></path>
          <g
            id="Group"
            transform="translate(7.000000, 38.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M69.3478261,231 L83.4492754,231 L83.4492754,237 L69.3478261,237 L69.3478261,231 Z M81.4347826,233 L81.4347826,235 L71.3623188,235 L71.3623188,233 L81.4347826,233 Z M181,186 L181,192 L0,192 L0,186 L181,186 Z M90.5,0 C96.2989899,0 101,4.70101013 101,10.5 C101,16.2989899 96.2989899,21 90.5,21 C84.7010101,21 80,16.2989899 80,10.5 C80,4.70101013 84.7010101,0 90.5,0 Z M90.5,1.90909091 C85.7553719,1.90909091 81.9090909,5.75537192 81.9090909,10.5 C81.9090909,15.2446281 85.7553719,19.0909091 90.5,19.0909091 C95.2446281,19.0909091 99.0909091,15.2446281 99.0909091,10.5 C99.0909091,5.75537192 95.2446281,1.90909091 90.5,1.90909091 Z"
              id="Rectangle"
            ></path>
            <path
              d="M97.5507246,231 L111.652174,231 L111.652174,237 L97.5507246,237 L97.5507246,231 Z M99.5652174,233 L99.5652174,235 L109.637681,235 L109.637681,233 L99.5652174,233 Z"
              id="Rectangle-Copy"
            ></path>
            <path
              d="M69.3478261,213 L111.652174,213 L111.652174,225 L69.3478261,225 L69.3478261,213 Z M71.3623188,215 L71.3623188,223 L109.637681,223 L109.637681,215 L71.3623188,215 Z"
              id="Rectangle"
            ></path>
            <path
              d="M90.5,238 C92.7251473,238 94.5289855,236.209139 94.5289855,234 C94.5289855,231.790861 92.7251473,230 90.5,230 C88.2748527,230 86.4710145,231.790861 86.4710145,234 C86.4710145,236.209139 88.2748527,238 90.5,238 Z M90.5,236 C89.3874264,236 88.4855072,235.104569 88.4855072,234 C88.4855072,232.895431 89.3874264,232 90.5,232 C91.6125736,232 92.5144928,232.895431 92.5144928,234 C92.5144928,235.104569 91.6125736,236 90.5,236 Z"
              id="Oval"
            ></path>
            <path
              d="M65.3188406,203 L115.681159,203 C119.01888,203 121.724638,205.686292 121.724638,209 L121.724638,239 C121.724638,242.313708 119.01888,245 115.681159,245 L65.3188406,245 C61.9811197,245 59.2753623,242.313708 59.2753623,239 L59.2753623,209 C59.2753623,205.686292 61.9811197,203 65.3188406,203 Z M65.3188406,205 C63.0936933,205 61.2898551,206.790861 61.2898551,209 L61.2898551,239 C61.2898551,241.209139 63.0936933,243 65.3188406,243 L115.681159,243 C117.906307,243 119.710145,241.209139 119.710145,239 L119.710145,209 C119.710145,206.790861 117.906307,205 115.681159,205 L65.3188406,205 Z"
              id="Rectangle"
            ></path>
            <polygon
              id="Line-3"
              points="38.9886956 217.592805 28.6478252 230.14072 30.2069566 231.407195 40.5478269 218.85928"
            ></polygon>
            <polygon
              id="Line-3-Copy"
              transform="translate(145.646666, 224.460204) rotate(126.000000) translate(-145.646666, -224.460204) "
              points="149.801874 217 139.855072 230.753946 141.491458 231.920408 151.438259 218.166461"
            ></polygon>
            <path
              d="M35.1014493,238 C42.3331778,238 48.1956522,232.179702 48.1956522,225 C48.1956522,217.820298 42.3331778,212 35.1014493,212 C27.8697207,212 22.0072464,217.820298 22.0072464,225 C22.0072464,232.179702 27.8697207,238 35.1014493,238 Z M35.1014493,236 C28.9822943,236 24.0217391,231.075132 24.0217391,225 C24.0217391,218.924868 28.9822943,214 35.1014493,214 C41.2206042,214 46.1811594,218.924868 46.1811594,225 C46.1811594,231.075132 41.2206042,236 35.1014493,236 Z"
              id="Oval"
            ></path>
            <path
              d="M145.898551,238 C153.130279,238 158.992754,232.179702 158.992754,225 C158.992754,217.820298 153.130279,212 145.898551,212 C138.666822,212 132.804348,217.820298 132.804348,225 C132.804348,232.179702 138.666822,238 145.898551,238 Z M145.898551,236 C139.779396,236 134.818841,231.075132 134.818841,225 C134.818841,218.924868 139.779396,214 145.898551,214 C152.017706,214 156.978261,218.924868 156.978261,225 C156.978261,231.075132 152.017706,236 145.898551,236 Z"
              id="Oval-Copy-4"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default NewBoiler
