import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Cushion from "../components/ui/Cushion"
import MaxWidth from "../components/ui/MaxWidth"
import Space from "../components/ui/Space"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"
import Reasons from "../components/BookingForm/Reasons"
import Boiler from "../components/BookingForm/Boiler"
import Radiators from "../components/BookingForm/Radiators"
import UnderfloorHeatingLoops from "../components/BookingForm/UnderfloorHeatingLoops"
import ContactDetails from "../components/BookingForm/ContactDetails"

const BookingForm = ({ data, location }) => {
  const { contactDetailsJson } = data
  const [step, setStep] = useState("reasons")
  const [form, setForm] = useState({
    values: { reasons: [], contactDetails: {} },
  })

  const formRef = useRef()

  return (
    <Frame
      currentPathname={location.pathname}
      contactDetails={contactDetailsJson}
    >
      <SEO title="Book a Powerflush" />
      <ThermalBackground component="div">
        <ConcaveBottom fill="vLightGrey">
          <Cushion
            component="div"
            horizontal="small"
            top="xxlarge"
            bottom="xxxxlarge"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
              <Cushion bottom={300}>
                <Cushion bottom="medium">
                  <Typography component="div" preset="hero" color="white">
                    Do you need a Powerflush?
                  </Typography>
                </Cushion>
                <Typography preset="body" color="white">
                  Find out if you need a Powerflush and book here with out easy
                  interactive form.
                </Typography>
              </Cushion>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>
      <Space top={-400}>
        <MaxWidth maxWidth={900} center>
          <Cushion component="div" horizontal="medium" bottom="xxlarge">
            <form
              name="bookingForm"
              method="post"
              action="/book-complete"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              ref={formRef}
              style={{ position: "relative", zIndex: 3 }}
            >
              <input hidden name="form-name" value="bookingForm" />
              <Reasons
                visible={step === "reasons"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("boiler")
                }}
              />
              <Boiler
                visible={step === "boiler"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("radiators")
                }}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("reasons")
                }}
              />
              <Radiators
                visible={step === "radiators"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("underfloorHeatingLoops")
                }}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("boiler")
                }}
              />
              <UnderfloorHeatingLoops
                visible={step === "underfloorHeatingLoops"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("contact")
                }}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("radiators")
                }}
              />
              <ContactDetails
                submit={() => {
                  formRef.current.submit()
                }}
                visible={step === "contact"}
                form={form}
                setForm={setForm}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("radiators")
                }}
              />
            </form>
          </Cushion>
        </MaxWidth>
      </Space>
    </Frame>
  )
}

BookingForm.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default BookingForm

export const pageQuery = graphql`
  query {
    contactDetailsJson {
      email
      mobile
      landline
    }
  }
`
