import React from "react"

const TankSystem = ({ fill }) => {
  return (
    <svg
      width="100%"
      length="auto"
      viewBox="0 0 360 360"
    >
      <title>Tank &amp; Cylinder System</title>
      <g
        id="Tank-&amp;-Cylinder-System"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(46.000000, 9.000000)">
          <polygon
            id="Line-8"
            fill={fill}
            fillRule="nonzero"
            points="180 85 137 85 137 86 180 86"
          ></polygon>
          <polygon
            id="Line-9"
            fill={fill}
            fillRule="nonzero"
            points="225 86 266 86 266 85 225 85"
          ></polygon>
          <polygon
            id="Line-5"
            fill={fill}
            fillRule="nonzero"
            points="203 108 203 285 204 285 204 108"
          ></polygon>
          <g
            id="Group-2"
            transform="translate(178.000000, 59.000000)"
            fill={fill}
            fillRule="nonzero"
          >
            <polygon
              id="Line-6"
              points="24.0916667 0.866666667 24.0916667 9.53333333 25.725 9.53333333 25.725 0.866666667"
            ></polygon>
            <polygon
              id="Line-4"
              points="27.9467942 23.3106534 35.440808 12.3755109 34.1198724 11.3560133 26.6258586 22.2911558"
            ></polygon>
            <path
              d="M24.5,52 C38.0309764,52 49,40.3594035 49,26 C49,11.6405965 38.0309764,0 24.5,0 C10.9690236,0 0,11.6405965 0,26 C0,40.3594035 10.9690236,52 24.5,52 Z M24.5,48.5333333 C12.7731538,48.5333333 3.26666667,38.4448164 3.26666667,26 C3.26666667,13.5551836 12.7731538,3.46666667 24.5,3.46666667 C36.2268462,3.46666667 45.7333333,13.5551836 45.7333333,26 C45.7333333,38.4448164 36.2268462,48.5333333 24.5,48.5333333 Z"
              id="Oval"
            ></path>
            <polygon
              id="Line-2"
              points="6.94166667 42.4666667 40.425 42.4666667 40.425 40.7333333 6.94166667 40.7333333"
            ></polygon>
            <path
              d="M24.5,30.3333333 C26.7551627,30.3333333 28.5833333,28.3932339 28.5833333,26 C28.5833333,23.6067661 26.7551627,21.6666667 24.5,21.6666667 C22.2448373,21.6666667 20.4166667,23.6067661 20.4166667,26 C20.4166667,28.3932339 22.2448373,30.3333333 24.5,30.3333333 Z M24.5,28.6 C23.1469024,28.6 22.05,27.4359403 22.05,26 C22.05,24.5640597 23.1469024,23.4 24.5,23.4 C25.8530976,23.4 26.95,24.5640597 26.95,26 C26.95,27.4359403 25.8530976,28.6 24.5,28.6 Z"
              id="Oval"
            ></path>
            <polygon
              id="Line-7"
              points="45.784375 28.6 39.1489583 28.6 39.1489583 30.3333333 45.784375 30.3333333"
            ></polygon>
            <polygon
              id="Line-7-Copy"
              points="9.85104167 28.6 3.215625 28.6 3.215625 30.3333333 9.85104167 30.3333333"
            ></polygon>
          </g>
          <path
            d="M163.68139,7.21725318 C179.940604,2.40575106 192.989195,0 202.82716,0 C212.508511,0 225.071573,2.32976412 240.516347,6.98929237 C257.405043,12.0844406 269,27.9609586 269,45.9908408 L269,240.720446 C269,257.781856 258.598383,273.028534 242.954603,278.897759 C226.780583,284.96592 213.404769,288 202.82716,288 C192.111989,288 178.271991,284.886489 161.307168,278.659468 C145.527506,272.867466 135,257.550932 135,240.385008 L135,46.2778819 C135,28.1698777 146.69382,12.2442936 163.68139,7.21725318 Z M165.059179,12.0998322 C150.195056,16.4984923 139.962963,30.4333782 139.962963,46.2778819 L139.962963,240.385008 C139.962963,255.405192 149.174531,268.807159 162.981735,273.87516 C179.455306,279.921865 192.771982,282.917647 202.82716,282.917647 C212.7373,282.917647 225.574305,280.005787 241.247315,274.125595 C254.935622,268.990023 264.037037,255.64918 264.037037,240.720446 L264.037037,45.9908408 C264.037037,30.2146944 253.89145,16.3227406 239.113841,11.8644859 C224.085988,7.3307383 211.961754,5.08235294 202.82716,5.08235294 C193.531086,5.08235294 180.909088,7.40945372 165.059179,12.0998322 Z"
            id="Rectangle"
            fill={fill}
            fillRule="nonzero"
          ></path>
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="0"
            y="52"
            width="158"
            height="255"
            rx="10"
          ></rect>
          <g
            id="Icon"
            transform="translate(0.000000, 48.000000)"
            fill={fill}
            fill-rule="nonzero"
          >
            <path
              d="M36.2083333,252.369942 L51.0208333,252.369942 L51.0208333,295 L36.2083333,295 L36.2083333,252.369942 Z M39.5,255.780347 L39.5,291.589595 L47.7291667,291.589595 L47.7291667,255.780347 L39.5,255.780347 Z"
              id="Rectangle"
            ></path>
            <path
              d="M106.979167,252.369942 L121.791667,252.369942 L121.791667,295 L106.979167,295 L106.979167,252.369942 Z M110.270833,255.780347 L110.270833,291.589595 L118.5,291.589595 L118.5,255.780347 L110.270833,255.780347 Z"
              id="Rectangle-Copy-2"
            ></path>
            <path
              d="M79,50.3034682 C83.9993276,50.3034682 88.0520833,46.1045203 88.0520833,40.9248555 C88.0520833,35.7451907 83.9993276,31.5462428 79,31.5462428 C74.0006724,31.5462428 69.9479167,35.7451907 69.9479167,40.9248555 C69.9479167,46.1045203 74.0006724,50.3034682 79,50.3034682 Z M79,48.5982659 C74.9096411,48.5982659 71.59375,45.162763 71.59375,40.9248555 C71.59375,36.6869479 74.9096411,33.2514451 79,33.2514451 C83.0903589,33.2514451 86.40625,36.6869479 86.40625,40.9248555 C86.40625,45.162763 83.0903589,48.5982659 79,48.5982659 Z"
              id="Oval"
            ></path>
            <polygon
              id="Line"
              points="4.52604167 192.687861 153.473958 192.687861 153.473958 189.277457 4.52604167 189.277457"
            ></polygon>
            <path
              d="M16.4583333,0 C44.9861111,0 65.8333333,0 79,0 C93.6296296,0 114.476852,0 141.541667,0 C150.631353,0 158,7.6344508 158,17.0520231 L158,238.728324 C158,248.145896 150.631353,255.780347 141.541667,255.780347 L16.4583333,255.780347 C7.36864683,255.780347 0,248.145896 0,238.728324 L0,17.0520231 C0,7.6344508 7.36864683,0 16.4583333,0 Z M16.4583333,5.11560694 C10.0955528,5.11560694 4.9375,10.4597225 4.9375,17.0520231 L4.9375,238.728324 C4.9375,245.320624 10.0955528,250.66474 16.4583333,250.66474 L141.541667,250.66474 C147.904447,250.66474 153.0625,245.320624 153.0625,238.728324 L153.0625,17.0520231 C153.0625,10.4597225 147.904447,5.11560694 141.541667,5.11560694 L16.4583333,5.11560694 Z"
              id="Rectangle"
            ></path>
            <g id="Group" transform="translate(23.041667, 204.624277)">
              <path
                d="M39.5,23.8728324 L51.0208333,23.8728324 L51.0208333,28.9884393 L39.5,28.9884393 L39.5,23.8728324 Z M41.1458333,25.5780347 L41.1458333,27.283237 L49.375,27.283237 L49.375,25.5780347 L41.1458333,25.5780347 Z"
                id="Rectangle"
              ></path>
              <path
                d="M62.5416667,23.8728324 L74.0625,23.8728324 L74.0625,28.9884393 L62.5416667,28.9884393 L62.5416667,23.8728324 Z M64.1875,25.5780347 L64.1875,27.283237 L72.4166667,27.283237 L72.4166667,25.5780347 L64.1875,25.5780347 Z"
                id="Rectangle-Copy"
              ></path>
              <path
                d="M39.5,8.52601156 L74.0625,8.52601156 L74.0625,18.7572254 L39.5,18.7572254 L39.5,8.52601156 Z M41.1458333,10.2312139 L41.1458333,17.0520231 L72.4166667,17.0520231 L72.4166667,10.2312139 L41.1458333,10.2312139 Z"
                id="Rectangle"
              ></path>
              <path
                d="M56.78125,29.8410405 C58.5991873,29.8410405 60.0729167,28.3141503 60.0729167,26.4306358 C60.0729167,24.5471214 58.5991873,23.0202312 56.78125,23.0202312 C54.9633127,23.0202312 53.4895833,24.5471214 53.4895833,26.4306358 C53.4895833,28.3141503 54.9633127,29.8410405 56.78125,29.8410405 Z M56.78125,28.1358382 C55.8722813,28.1358382 55.1354167,27.3723931 55.1354167,26.4306358 C55.1354167,25.4888786 55.8722813,24.7254335 56.78125,24.7254335 C57.6902187,24.7254335 58.4270833,25.4888786 58.4270833,26.4306358 C58.4270833,27.3723931 57.6902187,28.1358382 56.78125,28.1358382 Z"
                id="Oval"
              ></path>
              <path
                d="M36.2083333,0 L77.3541667,0 C80.0810726,0 82.2916667,2.29033524 82.2916667,5.11560694 L82.2916667,30.6936416 C82.2916667,33.5189133 80.0810726,35.8092486 77.3541667,35.8092486 L36.2083333,35.8092486 C33.4814274,35.8092486 31.2708333,33.5189133 31.2708333,30.6936416 L31.2708333,5.11560694 C31.2708333,2.29033524 33.4814274,0 36.2083333,0 Z M36.2083333,1.70520231 C34.390396,1.70520231 32.9166667,3.23209247 32.9166667,5.11560694 L32.9166667,30.6936416 C32.9166667,32.5771561 34.390396,34.1040462 36.2083333,34.1040462 L77.3541667,34.1040462 C79.172104,34.1040462 80.6458333,32.5771561 80.6458333,30.6936416 L80.6458333,5.11560694 C80.6458333,3.23209247 79.172104,1.70520231 77.3541667,1.70520231 L36.2083333,1.70520231 Z"
                id="Rectangle"
              ></path>
              <polygon
                id="Line-3"
                points="14.6966996 12.4418423 6.24824571 23.1402088 7.52205043 24.2200074 15.9705043 13.5216409"
              ></polygon>
              <polygon
                id="Line-3-Copy"
                transform="translate(101.835878, 18.296995) rotate(126.000000) translate(-101.835878, -18.296995) "
                points="105.230668 11.9364162 97.1041667 23.6630466 98.4410879 24.6575729 106.567589 12.9309425"
              ></polygon>
              <path
                d="M11.5208333,29.8410405 C17.4291296,29.8410405 22.21875,24.8786474 22.21875,18.7572254 C22.21875,12.6358034 17.4291296,7.6734104 11.5208333,7.6734104 C5.6125371,7.6734104 0.822916667,12.6358034 0.822916667,18.7572254 C0.822916667,24.8786474 5.6125371,29.8410405 11.5208333,29.8410405 Z M11.5208333,28.1358382 C6.52150575,28.1358382 2.46875,23.9368902 2.46875,18.7572254 C2.46875,13.5775607 6.52150575,9.37861272 11.5208333,9.37861272 C16.5201609,9.37861272 20.5729167,13.5775607 20.5729167,18.7572254 C20.5729167,23.9368902 16.5201609,28.1358382 11.5208333,28.1358382 Z"
                id="Oval"
              ></path>
              <path
                d="M102.041667,29.8410405 C107.949963,29.8410405 112.739583,24.8786474 112.739583,18.7572254 C112.739583,12.6358034 107.949963,7.6734104 102.041667,7.6734104 C96.1333704,7.6734104 91.34375,12.6358034 91.34375,18.7572254 C91.34375,24.8786474 96.1333704,29.8410405 102.041667,29.8410405 Z M102.041667,28.1358382 C97.0423391,28.1358382 92.9895833,23.9368902 92.9895833,18.7572254 C92.9895833,13.5775607 97.0423391,9.37861272 102.041667,9.37861272 C107.040994,9.37861272 111.09375,13.5775607 111.09375,18.7572254 C111.09375,23.9368902 107.040994,28.1358382 102.041667,28.1358382 Z"
                id="Oval-Copy-4"
              ></path>
            </g>
          </g>
          <path
            d="M110.7,35.7777778 L110.7,53 L96,53 L96,27.1666667 L96,22 L145,22 L140.1,35.7777778 L110.7,35.7777778 Z M107.433333,49.5555556 L107.433333,32.3333333 L137.836198,32.3333333 L140.286198,25.4444444 L99.2666667,25.4444444 L99.2666667,49.5555556 L107.433333,49.5555556 Z"
            id="Combined-Shape"
            fill={fill}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default TankSystem
