import React from "react"
import PropTypes from "prop-types"
import FormSection from "./FormSection"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Grid from "../ui/Grid"
import MaxWidth from "../ui/MaxWidth"
import SelectBox from "../SelectBox"
import ColdRads from "./icons/ColdRads"
import IntermittentHeating from "./icons/IntermittentHeating"
import LongStartup from "./icons/LongStartup"
import LowHeat from "./icons/LowHeat"
import Debris from "./icons/Debris"
import CreakingPipes from "./icons/CreakingPipes"
import InsuranceForm from "./icons/InsuranceForm"
import NewBoiler from "./icons/NewBoiler"
import Underfloor from "./icons/Underfloor"

const flushingReasons = [
  {
    id: "cold-rads",
    renderIcon: color => <ColdRads fill={color} />,
    value: "Sections of your radiators stay cold.",
  },
  {
    id: "intermittent-heating",
    renderIcon: color => <IntermittentHeating fill={color} />,
    value: "Intermittent heating or hot water.",
  },
  {
    id: "long-startup",
    renderIcon: color => <LongStartup fill={color} />,
    value: "Heating takes a long time to get started.",
  },
  {
    id: "low-heat",
    renderIcon: color => <LowHeat fill={color} />,
    value: "Heating doesn’t feel as hot as normal.",
  },
  {
    id: "debris",
    renderIcon: color => <Debris fill={color} />,
    value: "There is debris coming out of my central heating system.",
  },
  {
    id: "creaking-pipes",
    renderIcon: color => <CreakingPipes fill={color} />,
    value: "Creaking noises are coming from your pipes.",
  },
  {
    id: "insurance-form",
    renderIcon: color => <InsuranceForm fill={color} />,
    value: "Insurance company has requested a Powerflush.",
  },
  {
    id: "new-boiler",
    renderIcon: color => <NewBoiler fill={color} />,
    value: "You are having a new boiler fitted.",
  },
  {
    id: "underfloor-issues",
    renderIcon: color => <Underfloor fill={color} />,
    value: "Cold areas on my underfloor heating.",
  },
]

function validate(values) {
  const errors = []
  if (values.reasons.length < 1) {
    errors.push("Please select at least one reason")
  }

  return errors
}

const Reasons = ({ form, setForm, isEnd, onBack, onNext, visible }) => {
  const formReasons = form.values.reasons || []

  return (
    <FormSection
      question="Do you need a Powerflush?"
      description="There are many problems that can be fixed with a Powerflush,
    below are some of the most common ones for needing a
    Powerflush. Select all the reasons that apply to you."
      isEnd={isEnd}
      onBack={onBack}
      onNext={onNext}
      validate={validate}
      visible={visible}
      values={form.values}
      renderAnswers={() => (
        <Grid
          component="div"
          columns="1fr"
          responsive={[
            {
              minWidth: 900,
              props: {
                columns: "repeat(2, 1fr)",
                columnGap: "medium",
                rowGap: "medium",
              },
            },
          ]}
        >
          {flushingReasons.map(({ id, renderIcon, value }) => {
            const checked = formReasons.includes(id)
            return (
              <p>
                <SelectBox
                  id={id}
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    if (checked) {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          reasons: formReasons.filter(reason => reason !== id),
                        },
                      })
                      return null
                    }
                    setForm({
                      ...form,
                      values: { ...form.values, reasons: [...formReasons, id] },
                    })
                  }}
                  input={{ value, name: "reasons[]" }}
                >
                  <Flex alignItems="center">
                    <MaxWidth
                      component="div"
                      maxWidth={50}
                      style={{ width: "100%" }}
                    >
                      {renderIcon(checked ? "#FF7373" : "#D8D8D8")}
                    </MaxWidth>
                    <Cushion left="medium">
                      <span>{value}</span>
                    </Cushion>
                  </Flex>
                </SelectBox>
              </p>
            )
          })}
        </Grid>
      )}
    />
  )
}

export default Reasons
