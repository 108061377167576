import React from "react"

const CreakingPipes = ({ fill }) => {
  return (
    <svg
      width="100%"
      length="auto"
      viewBox="0 0 360 360"
      version="1.1"
    >
      <title>Creaking Pipes</title>
      <g
        id="Creaking-Pipes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Creaking-pipes"
          transform="translate(4.000000, 8.654659)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M338.620417,312.32018 L315.974838,312.32018 L315.974838,289.674601 L322.16463,289.674601 L322.16463,277.345341 L281,277.345341 L281,289.674601 L287.189792,289.674601 L287.189792,324.699763 C287.189792,333.808318 294.587347,341.15555 303.645579,341.15555 L338.67074,341.15555 L338.67074,347.345341 L351,347.345341 L351,306.180712 L338.67074,306.180712 L338.67074,312.32018 L338.620417,312.32018 Z"
            id="Path-Copy-15"
          ></path>
          <path
            d="M315.856115,262.057572 L315.856115,227.007212 C315.856115,217.892104 308.453237,210.539586 299.388489,210.539586 L264.338129,210.539586 L264.338129,204.345341 L252,204.345341 L252,245.539586 L264.338129,245.539586 L264.338129,239.345341 L287,239.345341 L287,262.007212 L280.805755,262.007212 L280.805755,274.345341 L322,274.345341 L322,262.007212 L315.856115,262.007212 L315.856115,262.057572 Z"
            id="Path-Copy-16"
          ></path>
          <path
            d="M34.9748382,289.724924 L34.9748382,312.370503 L12.3292595,312.370503 L12.3292595,306.180712 L0,306.180712 L0,347.345341 L12.3292595,347.345341 L12.3292595,341.15555 L47.3544213,341.15555 C56.4629763,341.15555 63.8102085,333.757994 63.8102085,324.699763 L63.8102085,289.674601 L70,289.674601 L70,277.345341 L28.8353702,277.345341 L28.8353702,289.674601 L34.9748382,289.674601 L34.9748382,289.724924 Z"
            id="Path"
          ></path>
          <path
            d="M29,262.016082 L29,274.345341 L70.1646298,274.345341 L70.1646298,262.016082 L63.9748382,262.016082 L63.9748382,239.370503 L86.620417,239.370503 L86.620417,245.560295 L99,245.560295 L99,204.345341 L86.620417,204.345341 L86.620417,210.535133 L51.5952552,210.535133 C42.4867002,210.535133 35.139468,217.932689 35.139468,226.99092 L35.139468,262.016082 L29,262.016082 Z"
            id="Path"
          ></path>
          <polygon
            id="Path"
            points="237.27907 204.345341 237.27907 210.707043 192.627907 210.60066 192.627907 189.068746 198 189.068746 198 176.345341 157.953488 176.345341 157.953488 189.068746 163.813953 189.068746 163.813953 210.60066 114 210.707043 114 204.345341 102.27907 204.345341 102.27907 245.451724 114 245.451724 114 238.111299 237.27907 238.111299 237.27907 245.451724 249 245.451724 249 204.345341"
          ></polygon>
          <path
            d="M114.499023,134.303349 L98.78125,134.303349 L94.0839844,42.3453413 L119.105957,42.3453413 L114.499023,134.303349 Z M93,163.119267 C93,158.662865 94.1743047,155.275447 96.5229492,152.956914 C98.8715938,150.63838 102.213846,149.47913 106.549805,149.47913 C110.825542,149.47913 114.107573,150.683545 116.395996,153.092412 C118.684419,155.501278 119.828613,158.84353 119.828613,163.119267 C119.828613,167.395005 118.669364,170.782422 116.35083,173.281621 C114.032296,175.780819 110.765321,177.0304 106.549805,177.0304 C102.274067,177.0304 98.9468706,175.825985 96.5681152,173.417119 C94.1893599,171.008252 93,167.57567 93,163.119267 Z"
            id="!"
            transform="translate(106.414307, 109.687871) rotate(-15.000000) translate(-106.414307, -109.687871) "
          ></path>
          <path
            d="M257.499023,105.303349 L241.78125,105.303349 L237.083984,13.3453413 L262.105957,13.3453413 L257.499023,105.303349 Z M236,134.119267 C236,129.662865 237.174305,126.275447 239.522949,123.956914 C241.871594,121.63838 245.213846,120.47913 249.549805,120.47913 C253.825542,120.47913 257.107573,121.683545 259.395996,124.092412 C261.684419,126.501278 262.828613,129.84353 262.828613,134.119267 C262.828613,138.395005 261.669364,141.782422 259.35083,144.281621 C257.032296,146.780819 253.765321,148.0304 249.549805,148.0304 C245.274067,148.0304 241.946871,146.825985 239.568115,144.417119 C237.18936,142.008252 236,138.57567 236,134.119267 Z"
            id="!-copy"
            transform="translate(249.414307, 80.687871) rotate(15.000000) translate(-249.414307, -80.687871) "
          ></path>
          <polygon
            id="Path-2"
            transform="translate(167.810029, 86.904010) rotate(-7.000000) translate(-167.810029, -86.904010) "
            points="199.620058 76.4969193 161.798617 49.9196905 190.848095 27.1511801 181.59487 15.3453413 136.644349 50.5768309 172.822908 75.9996021 136 99.8847319 173.174633 127.012167 145.022552 146.033843 153.420414 158.462678 199.268333 127.484354 162.442966 100.611789"
          ></polygon>
          <polygon
            id="Path-3"
            points="46.9539968 147.781519 69.6034785 112.863569 21.7366254 15.0486949 8.26337458 21.6419878 52.3965215 111.827114 27.0460032 150.909163 86.2624529 199.159604 95.7375471 187.531079"
          ></polygon>
          <polygon
            id="Path-4"
            points="295.515967 88.3101506 352.112938 8.69068267 339.887062 0 278.484033 86.3805321 301.647096 142.357933 248.015332 175.991412 255.984668 188.699271 320.352904 148.33275"
          ></polygon>
          <polygon
            id="Path-5"
            points="303.129461 197.606136 350.129461 166.606136 341.870539 154.084546 294.870539 185.084546"
          ></polygon>
          <polygon
            id="Path-6"
            points="51.9921114 198.748126 14.9921114 165.748126 5.00788865 176.942557 42.0078886 209.942557"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}

export default CreakingPipes
