import React from "react"

const InsuranceForm = ({ fill }) => {
  return (
    <svg
      width="100%"
      length="auto"
      viewBox="0 0 360 360"
      version="1.1"
    >
      <title>Insurance</title>
      <g
        id="Insurance"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(79.000000, 19.000000)" fill={fill}>
          <path
            d="M57,35 L22,35 C15.9248678,35 11,39.9248678 11,46 L11,293 C11,299.075132 15.9248678,304 22,304 L181,304 C187.075132,304 192,299.075132 192,293 L192,46 C192,39.9248678 187.075132,35 181,35 L147,35 L147,24 L181,24 C193.150264,24 203,33.8497355 203,46 L203,293 C203,305.150264 193.150264,315 181,315 L22,315 C9.8497355,315 0,305.150264 0,293 L0,46 C0,33.8497355 9.8497355,24 22,24 L57,24 L57,35 Z"
            id="Combined-Shape"
            fillRule="nonzero"
          ></path>
          <path
            d="M93,0 L112,0 C116.334914,-7.96310831e-16 119.864543,3.44783777 119.996193,7.75082067 L120,8 L131,8 C136.522847,8 141,12.4771525 141,18 L141,35 L63,35 L63,18 C63,12.4771525 67.4771525,8 73,8 L85,8 C85,3.581722 88.581722,8.11624501e-16 93,0 Z"
            id="Combined-Shape"
          ></path>
          <rect id="Rectangle" x="28" y="58" width="146" height="14"></rect>
          <rect
            id="Rectangle-Copy"
            x="28"
            y="80"
            width="146"
            height="14"
          ></rect>
          <rect
            id="Rectangle-Copy-2"
            x="28"
            y="102"
            width="146"
            height="14"
          ></rect>
          <rect
            id="Rectangle-Copy-3"
            x="28"
            y="124"
            width="146"
            height="14"
          ></rect>
          <rect
            id="Rectangle-Copy-4"
            x="28"
            y="146"
            width="146"
            height="14"
          ></rect>
          <rect
            id="Rectangle-Copy-5"
            x="28"
            y="168"
            width="146"
            height="14"
          ></rect>
          <rect
            id="Rectangle-Copy-6"
            x="28"
            y="190"
            width="113"
            height="14"
          ></rect>
          <polygon
            id="Path"
            points="135.272407 272.484768 164.049656 232 174 241.179497 137.165121 293 113 270.706935 121.05763 259.3712"
          ></polygon>
          <polygon
            id="Path"
            points="99.1428571 251 99.1428571 288 75.4285714 288 75.4285714 274 64.7571429 274 64.7571429 288 41.0428571 288 41.0428571 251 28 251 69.5 227 111 251"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}

export default InsuranceForm
