import React from "react"
import PropTypes from "prop-types"
import FormSection from "./FormSection"
import Typography from "../ui/Typography"
import SelectBox from "../SelectBox"

const radiatorOptions = [
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16+",
]

function validate(values) {
  const errors = []

  if (!values.radiators) {
    errors.push("Please let us know how many radiators you have.")
  }

  return errors
}

const Radiators = ({ form, setForm, visible, isEnd, onBack, onNext }) => {
  const formRadiators = form.values.radiators || null

  return (
    <FormSection
      question="How many radiators do you have?"
      description="Select the amount of radiators you have in your home including towel rails."
      renderAnswers={() => (
        <Typography component="div" align="center">
          {radiatorOptions.map((value, index) => {
            const checked = value === formRadiators
            return (
              <SelectBox
                id={`radiators_${value}`}
                type="radio"
                checked={checked}
                onChange={() => {
                  setForm({
                    ...form,
                    values: { ...form.values, radiators: value },
                  })
                }}
                input={{ value, name: "radiators" }}
                style={{
                  display: "inline-block",
                  padding: 0,
                  position: "relative",
                  height: 45,
                  width: 45,
                  lineHeight: "0px",
                  borderRadius: "50%",
                  borderWidth: 3,
                  marginLeft: 6,
                  marginRight: 6,
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%)",
                  }}
                >
                  {value}
                </span>
              </SelectBox>
            )
          })}
        </Typography>
      )}
      isEnd={isEnd}
      onBack={onBack}
      values={form.values}
      onNext={onNext}
      validate={validate}
      visible={visible}
    />
  )
}

export default Radiators
