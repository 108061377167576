import React from "react"

const LowHeat = ({ fill }) => {
  return (
    <svg
      width="100%"
      length="auto"
      viewBox="0 0 360 360"
      version="1.1"
    >
      <title>Not as Hot</title>
      <g
        id="Not-as-Hot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Not-as-hot" transform="translate(33.000000, 15.000000)">
          <path
            d="M193.846073,306 C179.412144,306 166.727782,304.681034 155.792988,302.043103 C139.390796,298.086207 117.106671,285.465231 116.132956,284.651163 C91.9766797,264.455469 82.118665,237.376678 79.0307298,226.202586 C75.7502914,214.331897 75.7502914,193.228448 84.2794312,172.125 C92.808571,151.021552 100.025535,147.064655 111.179026,130.577586 C122.332516,114.090517 138.078621,87.7112069 129.549481,63.3103448 C145.951673,79.7974138 153.824725,101.560345 149.888199,134.534483 C170.226917,116.068966 193.846073,44.8448276 129.549481,0 C155.730521,4.61637931 203.195288,28.2465685 226.416741,49.8139535 C283.646791,102.967499 285.116175,126.313953 290.452487,149.44186 C295.788799,172.569767 298.820101,224.224138 271.264418,261.814655 C252.893964,286.875 227.087848,301.603448 193.846073,306 Z M205.707848,136 C205.156797,150.891728 204.069115,161.098387 202.444803,166.619978 C198.524778,179.945476 191.258318,185.91381 184.011497,190.565928 C173.638502,197.224902 167.493826,204.984975 165.577468,213.846147 C163.832935,226.271182 166.041082,236.037655 172.201908,243.145568 C181.443149,253.807437 205.835124,253.168605 217.038964,249.85231 C228.242804,246.536015 246.02191,227.249453 236.090312,187.960436 C229.469247,161.767759 219.341759,144.447613 205.707848,136 Z"
            id="Shape-Copy-4"
            fill={fill}
          ></path>
          <path
            d="M129.046937,240.926923 C134.106347,249.644934 137,259.754213 137,270.533333 C137,303.375866 110.137085,330 77,330 C43.862915,330 17,303.375866 17,270.533333 C17,250.46854 27.0264966,232.724745 42.3846154,221.95509 L42.3846154,141.307692 C42.3846154,122.360077 57.882451,107 77,107 C96.117549,107 111.615385,122.360077 111.615385,141.307692 L111.615385,221.95509 C118.722882,226.939118 124.688509,233.416793 129.046937,240.926923 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
          <g
            id="Group"
            transform="translate(0.000000, 139.000000)"
            fill={fill}
          >
            <path
              d="M69.5,0 C84.6347263,0 96.9038462,12.2605099 96.9038462,27.3846154 L96.9038462,91.7578742 C102.530615,95.7361567 107.253403,100.906678 110.703825,106.90131 C114.709191,113.860082 117,121.929372 117,130.533333 C117,156.748449 95.7335256,178 69.5,178 C43.2664744,178 22,156.748449 22,130.533333 C22,114.51749 29.9376432,100.354281 42.0961538,91.7578742 L42.0961538,27.3846154 C42.0961538,12.2605099 54.3652737,0 69.5,0 Z M80.4615385,73.9384615 L58.5384615,73.9384615 L58.5384615,98.6288584 C45.7661605,103.140051 36.6153846,115.312393 36.6153846,129.620513 C36.6153846,147.769439 51.3383284,162.482051 69.5,162.482051 C87.6616716,162.482051 102.384615,147.769439 102.384615,129.620513 C102.384615,115.312393 93.2338395,103.140051 80.4615385,98.6288584 L80.4615385,98.6288584 L80.4615385,73.9384615 Z"
              id="Combined-Shape"
            ></path>
            <rect id="Rectangle" x="3" y="84" width="32" height="5"></rect>
            <rect
              id="Rectangle-Copy-8"
              x="12"
              y="56"
              width="23"
              height="5"
            ></rect>
            <rect
              id="Rectangle-Copy-10"
              x="19"
              y="40"
              width="16"
              height="5"
            ></rect>
            <rect
              id="Rectangle-Copy-11"
              x="19"
              y="71"
              width="16"
              height="5"
            ></rect>
            <rect
              id="Rectangle-Copy-9"
              x="3"
              y="27"
              width="32"
              height="5"
            ></rect>
            <path
              d="M12,71.0179402 C12,74.0558291 11.5065508,76.312285 10.5196375,77.7873754 C9.53272417,79.2624658 8.02417934,80 5.9939577,80 C4.02818755,80 2.53776943,79.2385458 1.52265861,77.7156146 C0.507547795,76.1926834 0,73.9601476 0,71.0179402 C0,67.9322105 0.493449245,65.6578146 1.48036254,64.1946844 C2.46727583,62.7315541 3.97179251,62 5.9939577,62 C7.96778429,62 9.46223057,62.7654409 10.4773414,64.2963455 C11.4924522,65.8272502 12,68.0677593 12,71.0179402 Z M2.76923077,71.0113309 C2.76923077,73.2699753 3.02494547,74.882729 3.53638254,75.8496403 C4.0478196,76.8165516 4.86485925,77.3 5.98752599,77.3 C7.11019272,77.3 7.93139033,76.8089977 8.45114345,75.8269784 C8.97089657,74.8449591 9.23076923,73.2397593 9.23076923,71.0113309 C9.23076923,68.7904565 8.97089657,67.1833683 8.45114345,66.190018 C7.93139033,65.1966677 7.11019272,64.7 5.98752599,64.7 C4.86485925,64.7 4.0478196,65.1872253 3.53638254,66.1616906 C3.02494547,67.136156 2.76923077,68.7526865 2.76923077,71.0113309 Z"
              id="0"
              fillRule="nonzero"
              transform="translate(6.000000, 71.000000) scale(-1, 1) translate(-6.000000, -71.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LowHeat
