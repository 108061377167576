import React from "react"

const CombiBoiler = ({ fill }) => {
  return (
    <svg
      width="100%"
      length="auto"
      viewBox="0 0 360 360"
      version="1.1"
    >
      <title>Combi Boiler</title>
      <g
        id="Combi-Boiler"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(84.000000, 8.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M44,295 L62,295 L62,345 L44,345 L44,295 Z M48,299 L48,341 L58,341 L58,299 L48,299 Z"
            id="Rectangle"
          ></path>
          <path
            d="M130,295 L148,295 L148,345 L130,345 L130,295 Z M134,299 L134,341 L144,341 L144,299 L134,299 Z"
            id="Rectangle-Copy-2"
          ></path>
          <path
            d="M96,59 C102.075132,59 107,54.0751322 107,48 C107,41.9248678 102.075132,37 96,37 C89.9248678,37 85,41.9248678 85,48 C85,54.0751322 89.9248678,59 96,59 Z M96,57 C91.0294373,57 87,52.9705627 87,48 C87,43.0294373 91.0294373,39 96,39 C100.970563,39 105,43.0294373 105,48 C105,52.9705627 100.970563,57 96,57 Z"
            id="Oval"
          ></path>
          <polygon id="Line" points="5 225 186 225 186 221 5 221"></polygon>
          <path
            d="M20,0 L172,0 C183.045695,0 192,8.92445732 192,19.9333333 L192,279.066667 C192,290.075543 183.045695,299 172,299 L20,299 C8.954305,299 0,290.075543 0,279.066667 L0,19.9333333 C0,8.92445732 8.954305,0 20,0 Z M20,5.98 C12.2680135,5.98 6,12.2271201 6,19.9333333 L6,279.066667 C6,286.77288 12.2680135,293.02 20,293.02 L172,293.02 C179.731986,293.02 186,286.77288 186,279.066667 L186,19.9333333 C186,12.2271201 179.731986,5.98 172,5.98 L20,5.98 Z"
            id="Rectangle"
          ></path>
          <g id="Group" transform="translate(29.000000, 239.000000)">
            <path
              d="M47,28 L61,28 L61,34 L47,34 L47,28 Z M49,30 L49,32 L59,32 L59,30 L49,30 Z"
              id="Rectangle"
            ></path>
            <path
              d="M75,28 L89,28 L89,34 L75,34 L75,28 Z M77,30 L77,32 L87,32 L87,30 L77,30 Z"
              id="Rectangle-Copy"
            ></path>
            <path
              d="M47,10 L89,10 L89,22 L47,22 L47,10 Z M49,12 L49,20 L87,20 L87,12 L49,12 Z"
              id="Rectangle"
            ></path>
            <path
              d="M68,35 C70.209139,35 72,33.209139 72,31 C72,28.790861 70.209139,27 68,27 C65.790861,27 64,28.790861 64,31 C64,33.209139 65.790861,35 68,35 Z M68,33 C66.8954305,33 66,32.1045695 66,31 C66,29.8954305 66.8954305,29 68,29 C69.1045695,29 70,29.8954305 70,31 C70,32.1045695 69.1045695,33 68,33 Z"
              id="Oval"
            ></path>
            <path
              d="M43,0 L93,0 C96.3137085,0 99,2.6862915 99,6 L99,36 C99,39.3137085 96.3137085,42 93,42 L43,42 C39.6862915,42 37,39.3137085 37,36 L37,6 C37,2.6862915 39.6862915,0 43,0 Z M43,2 C40.790861,2 39,3.790861 39,6 L39,36 C39,38.209139 40.790861,40 43,40 L93,40 C95.209139,40 97,38.209139 97,36 L97,6 C97,3.790861 95.209139,2 93,2 L43,2 Z"
              id="Rectangle"
            ></path>
            <polygon
              id="Line-3"
              points="16.8592805 14.5928049 6.59280491 27.1407195 8.14071951 28.4071951 18.4071951 15.8592805"
            ></polygon>
            <polygon
              id="Line-3-Copy"
              transform="translate(122.749927, 21.460204) rotate(126.000000) translate(-122.749927, -21.460204) "
              points="126.875242 14 117 27.7539461 118.624613 28.9204075 128.499855 15.1664614"
            ></polygon>
            <path
              d="M13,35 C20.1797017,35 26,29.1797017 26,22 C26,14.8202983 20.1797017,9 13,9 C5.82029825,9 0,14.8202983 0,22 C0,29.1797017 5.82029825,35 13,35 Z M13,33 C6.92486775,33 2,28.0751322 2,22 C2,15.9248678 6.92486775,11 13,11 C19.0751322,11 24,15.9248678 24,22 C24,28.0751322 19.0751322,33 13,33 Z"
              id="Oval"
            ></path>
            <path
              d="M123,35 C130.179702,35 136,29.1797017 136,22 C136,14.8202983 130.179702,9 123,9 C115.820298,9 110,14.8202983 110,22 C110,29.1797017 115.820298,35 123,35 Z M123,33 C116.924868,33 112,28.0751322 112,22 C112,15.9248678 116.924868,11 123,11 C129.075132,11 134,15.9248678 134,22 C134,28.0751322 129.075132,33 123,33 Z"
              id="Oval-Copy-4"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CombiBoiler
